import React from 'react';
import createIdHelper from '../../utils/createIdHelper';
import MfeNotFound from '../MfeNotFound';
import useAssetImport from './hooks/useAssetImport';
import * as S from './styles';
import * as T from './types';

const LazyMfeLoader: React.FC<T.LazyMfeLoaderPropsType> = ({
  children,
  ...props
}) => {
  const { componentProps, isLoading, mfe } = useAssetImport({
    ...props
  });

  if (mfe?.default) {
    return <mfe.default {...componentProps} />;
  } else if (isLoading) {
    return (
      <S.Container data-testid={createIdHelper('loading')}>
        Loading...
      </S.Container>
    );
  } else if (children) {
    return <>{children}</>;
  } else {
    return <MfeNotFound />;
  }
};

export default LazyMfeLoader;

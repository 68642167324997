export default function getErrorParams() {
  const urlParams = new URLSearchParams(window.location.search);
  const errorType = urlParams.get('error_type');
  if (errorType) {
    return {
      errorType,
      errorDescription: urlParams.get('error_description'),
      status: urlParams.get('status'),
      request_id: urlParams.get('request_id')
    };
  }
}

/* eslint-disable camelcase */
import de_DE from './de_DE.json';
import de_LI from './de_LI.json';
import en_CA from './en_CA.json';
import en_US from './en_US.json';
import es_ES from './es_ES.json';
import fr_CA from './fr_CA.json';
import fr_FR from './fr_FR.json';
import fr_MC from './fr_MC.json';
import it_IT from './it_IT.json';
import pt_BR from './pt_BR.json';
import zh_CN from './zh_CN.json';

export default {
  de_DE,
  de_LI,
  en_CA,
  en_US,
  es_ES,
  fr_CA,
  fr_FR,
  fr_MC,
  it_IT,
  pt_BR,
  zh_CN
};

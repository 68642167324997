import styled from 'styled-components';
import tokens from '@veneer/tokens';

type themeModeType = {
  themeMode: string;
};
export const FlexColumDiv = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
`;

export const Container = styled(FlexColumDiv)<themeModeType>`
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  padding: 24px;
  gap: ${tokens.layout.size7};

  ${({ themeMode }) => {
    if (themeMode === 'contrast')
      return 'background: ' + tokens.color.black + ';';
    if (themeMode === 'dark') return 'background: ' + tokens.color.gray7 + ';';
    return 'background: ' + tokens.color.gray2 + ';';
  }}
`;

export const Header = styled(FlexColumDiv)`
  flex-direction: row;
  gap: ${tokens.layout.size3};
`;

export const Content = styled(FlexColumDiv)`
  gap: ${tokens.layout.size5};
  line-height: 1.5;
`;

export const HeaderTitle = styled.p<themeModeType>`
  font-size: ${tokens.typography.size7};
  ${({ themeMode }) => {
    if (themeMode === 'contrast' || themeMode === 'dark')
      return 'color: ' + tokens.color.white + ';';
    return 'color: ' + tokens.color.gray6 + ';';
  }}
`;

export const ErrorTitle = styled(HeaderTitle)`
  font-size: ${tokens.typography.size6};
  margin-bottom: ${tokens.layout.size1};
`;

export const Text = styled(HeaderTitle)<themeModeType>`
  font-size: ${tokens.typography.size3};
  ${({ themeMode }) => {
    if (themeMode === 'contrast') return 'color: ' + tokens.color.white + ';';
    return 'color: ' + tokens.color.gray4 + ';';
  }}
`;

export const LoginLinkButton = styled.button<themeModeType>`
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  text-decoration: none;
  font-weight: 600;
  font-size: ${tokens.typography.size4};
  color: ${tokens.color.hpBlue5};
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: none;
    color: ${tokens.color.hpBlue6};
  }
  ${({ themeMode }) => {
    if (themeMode === 'contrast')
      return 'color: ' + tokens.color.highContrastYellow + ';';
    return 'color: ' + tokens.color.hpBlue5 + ';';
  }}
`;

import { useState, useEffect } from 'react';
import getUrlErrorParams from '../../../../utils/getUrlErrorParams';

function useLoginLocation() {
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const [error, setError] = useState(getUrlErrorParams());

  useEffect(() => {
    const updateCurrentPath = () => {
      setCurrentPath(window.location.pathname);
      setError(getUrlErrorParams());
    };

    window.addEventListener('popstate', updateCurrentPath);

    return () => {
      window.removeEventListener('popstate', updateCurrentPath);
    };
  }, []);

  return {
    currentPath,
    error
  };
}

export default useLoginLocation;

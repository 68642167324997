import { IconXCircle } from '@veneer/core';
import React from 'react';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import createIdHelper from '../../utils/createIdHelper';
import * as S from './styles';

const MfeNotFound = (props: any) => {
  const { translate } = useDependencyManagerContext();

  return (
    <S.Container
      data-testid={createIdHelper('mfe_not_found')}
      {...props}
    >
      <IconXCircle
        filled
        size={48}
        color="red6"
      />
      <S.ErrorText data-testid={createIdHelper('error_loading')}>
        {translate(
          'login.error.loading',
          'An error has occurred while loading this widget!'
        )}
      </S.ErrorText>
    </S.Container>
  );
};

export default MfeNotFound;

import { LogoHp } from '@veneer/core';
import { ThemeProvider } from '@veneer/theme';
import React, { useEffect, useRef } from 'react';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import createIdHelper from '../../utils/createIdHelper';
import isEmailValid from '../../utils/isEmailValid';
import * as S from './styles';
import * as T from './types';

const LoginForm: T.LoginFormType = ({
  handleSubmit,
  logoColor = 'auto'
}: T.LoginFormPropsType) => {
  const { translate, globalTranslate, northboundAPIs, properties } =
    useDependencyManagerContext();
  const { store, theme, navigation } = northboundAPIs.v1;
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [email, setEmail] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const { appName } = store?.state?.manifest?.portal || {};
  const { emailGathering = true } =
    store?.state?.manifest?.services?.login || {};
  const inputRef = useRef(null);
  useEffect(() => {
    emailGathering && inputRef?.current?.focus?.();
  }, []);

  function getLegalProperties(property: any) {
    const text = property?.text?.value;
    const translationKey = property?.text?.translationKey;
    const url = property?.url?.value;
    const newTab = property?.url?.newTab;
    const enabled = property?.enable;

    return { text, translationKey, url, newTab, enabled };
  }

  const termsOfServiceProps = getLegalProperties(properties?.termsOfService);
  const privacyStatementProps = getLegalProperties(
    properties?.privacyStatement
  );
  const introTextProps = getLegalProperties(properties?.introText);

  const {
    text: termsOfServiceText,
    translationKey: termsOfServiceTranslationKey,
    url: termsOfServiceUrl,
    newTab: termsOfServiceNewTab,
    enabled: termsOfServiceEnabled
  } = termsOfServiceProps;

  const {
    text: privacyStatementText,
    translationKey: privacyStatementTranslationKey,
    url: privacyStatementUrl,
    newTab: privacyStatementNewTab,
    enabled: privacyStatementEnabled
  } = privacyStatementProps;

  const {
    text: introTextValue,
    translationKey: introTextTranslationkey,
    enabled: introTextEnabled
  } = introTextProps;

  const missingTermsOrPrivacyText =
    (termsOfServiceEnabled && !termsOfServiceText) ||
    (privacyStatementEnabled && !privacyStatementText);

  const missingAllTexts =
    privacyStatementEnabled &&
    termsOfServiceEnabled &&
    introTextEnabled &&
    !(termsOfServiceText || privacyStatementText || introTextValue);

  const onChangeEmail = (value: string) => {
    const email = value.trim();
    setEmail(email);
  };

  const validateEmail = (value: string) => {
    if (!isEmailValid(value)) {
      setError(
        translate(
          'login.error.enter-email',
          'Please enter a valid email address'
        )
      );
      return false;
    }
    return true;
  };

  function handleUrlPropsClick(newTab: any, propsUrl: string) {
    if (newTab) {
      window.open(propsUrl, '_blank')?.focus?.();
    } else if (propsUrl?.startsWith('/')) {
      navigation.push(propsUrl);
    } else {
      navigation.redirect(propsUrl);
    }
  }

  const consentIntro = introTextEnabled ? (
    <>{globalTranslate(introTextTranslationkey, introTextValue)}</>
  ) : (
    <>{translate('consent.intro', 'By clicking "Continue", I agree to ')}</>
  );

  const termsOfService = termsOfServiceEnabled ? (
    <a
      onClick={() => {
        handleUrlPropsClick(termsOfServiceNewTab, termsOfServiceUrl);
      }}
    >
      {globalTranslate(termsOfServiceTranslationKey, termsOfServiceText)}
    </a>
  ) : (
    <a
      onClick={() => {
        handleUrlPropsClick(true, '/hp-commandcenter-terms-of-service');
      }}
    >
      {translate(
        'consent.termsOfService',
        "HP Command Center's Terms of Service"
      )}
    </a>
  );

  const propertieseparator = missingTermsOrPrivacyText ? (
    ''
  ) : (
    <>{translate('consent.separator', ' and ')}</>
  );

  const privacyStatement = privacyStatementEnabled ? (
    <a
      onClick={() => {
        handleUrlPropsClick(privacyStatementNewTab, privacyStatementUrl);
      }}
    >
      {globalTranslate(privacyStatementTranslationKey, privacyStatementText)}
    </a>
  ) : (
    <a
      onClick={() => {
        handleUrlPropsClick(
          true,
          'https://www.hp.com/us-en/privacy/privacy.html?jumpid=in_R11928_/us/en/corp/privacy-central/privacy-statements'
        );
      }}
    >
      {translate('consent.privacyStatement', "HP's Privacy Statement")}
    </a>
  );

  const endText = missingAllTexts ? '' : '.';

  return (
    <ThemeProvider mode={theme.getUserThemeMode()}>
      <S.Container themeMode={theme.getUserThemeMode()}>
        <S.CustomCard
          border="dropShadow"
          content={
            <>
              <S.Header themeMode={theme.getUserThemeMode()}>
                <LogoHp
                  appearance="singlecolor"
                  background={logoColor}
                  size={46}
                />
                <p data-testid={createIdHelper('title')}>
                  {/* TODO: fix to return a React child */}
                  {translate('login.header', {
                    defaultValue: `Sign in to HP ${appName}`,
                    appName
                  })}
                </p>
              </S.Header>
              <S.CardForm
                onSubmit={(e) => {
                  e.preventDefault();
                  setError(undefined);
                  if (validateEmail(email)) {
                    setLoading(true);
                    handleSubmit(email);
                  }
                }}
              >
                <S.CustomTextBox
                  data-testid={createIdHelper('username')}
                  id="login-email"
                  name="email"
                  type="text"
                  placeholder={translate(
                    'login.placeholder',
                    'Username (Email)'
                  )}
                  value={email}
                  onChange={onChangeEmail}
                  helperText={error}
                  error={!!error}
                  ref={inputRef}
                  autoComplete={'on'}
                />
                <p data-testid={createIdHelper('agree')}>
                  {consentIntro}
                  {termsOfService}
                  {propertieseparator}
                  {privacyStatement}
                  {endText}
                </p>
                <S.CustomButton
                  data-testid={createIdHelper('continue_button')}
                  id="btnContinue"
                  type="submit"
                  expanded={true}
                  loading={loading}
                >
                  {translate('login.continue', 'Continue')}
                </S.CustomButton>
              </S.CardForm>
            </>
          }
        />
      </S.Container>
    </ThemeProvider>
  );
};

export default LoginForm;

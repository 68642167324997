const getStack = (apiName: string, isOAuthToken: any, stackName: string) => {
  if (stackName === 'prod') return '';

  const shouldUseOauthSyntax =
    isOAuthToken && apiName === 'ws-hp.com/accountmgtsvc';

  const separator = shouldUseOauthSyntax ? '.' : '-';

  let stackValue;

  if (stackName === 'stage') {
    stackValue = shouldUseOauthSyntax ? 'stg' : 'stage';
  } else if (stackName === 'pie' || stackName === 'dev') {
    stackValue = shouldUseOauthSyntax ? 'itg' : 'pie';
  }

  return `${stackValue}${separator}`;
};

const getBaseUrl = (
  apiVersion: string,
  apiName: string,
  isOAuthToken: any,
  stackName: string
) => {
  const stack = getStack(apiName, isOAuthToken, stackName);

  let partialURL = `https://${stack}us1.api.ws-hp.com/ucde/ucde/v2/ecosystem`;
  let path;

  if (apiVersion === '1') {
    if (apiName === 'ws-hp.com/devices') {
      partialURL = `https://devices.${stack}us1.api.ws-hp.com`;
      path = '/devices/v1';
    } else if (apiName === 'ws-hp.com/messagesdb') {
      partialURL = `https://${stack}us1.api.ws-hp.com`;
      path = '/atlas/v1';
    }
  }

  if (apiVersion === '2') {
    if (apiName === 'ws-hp.com/accountmgtsvc') {
      if (isOAuthToken) {
        partialURL = `https://oauth-auth.${stack}oc.hp.com`;
        path = '/oauth2/v1/stratus';
      } else {
        path = '/accountmgtsvc';
      }
    } else if (apiName === 'ws-hp.com/uimgtsvc') {
      path = '/uimgtsvc';
    } else if (apiName === 'ws-hp.com/programmgtsvc') {
      path = '/programmgtsvc';
    }
  }

  return path !== undefined ? `${partialURL}${path}` : undefined;
};

export default getBaseUrl;

import { DirectionProvider, ThemeProvider } from '@veneer/core';
import React, { useEffect, useMemo, useState } from 'react';
import resources from 'src/assets/locale';
import projectNames from '../configs/projectNames';
import dependencyManagerContext from '../dependencyManagerContext';
import * as T from '../types';
import getCommonsNorthboundAPIs from '../utils/getCommonsNorthboundAPIs';

/**
 * This component is responsible to manage global dependencies and provide them to the children components
 * It should be used by the main component of your project
 * You can use the hook to get the dependencies in any child component
 * It is configured by default to be used inside MFE environment, but you can change it to be used in any other environment
 */
const DependencyManagerProvider: React.FC<
  T.DependencyManagerProviderPropsType
> = ({ children, properties }) => {
  const [globalTranslate, setGlobalTranslate] =
    useState<T.DependencyManagerContextType['globalTranslate']>();
  const [translate, setTranslate] = useState<T.TranslateType | undefined>();

  const commonsNorthboundAPIs = React.useMemo(
    () => getCommonsNorthboundAPIs(),
    []
  );

  const directionValue =
    commonsNorthboundAPIs?.v1?.localization?.useReactGetLanguageDirection?.(
      React
    );

  const { themeProviderProps, userThemeMode } = useMemo(() => {
    return {
      themeProviderProps:
        commonsNorthboundAPIs?.v1?.theme?.getThemeProviderProperties?.(),
      userThemeMode: commonsNorthboundAPIs?.v1?.theme?.getUserThemeMode?.()
    };
  }, [commonsNorthboundAPIs?.v1?.theme]);

  // Create a translator function by providing the resource files
  useEffect(() => {
    commonsNorthboundAPIs.v1.localization
      .createTranslatorFunction(resources)
      .then((response) => setTranslate(() => response));
  }, [commonsNorthboundAPIs.v1.localization]);

  useEffect(() => {
    commonsNorthboundAPIs.v1.localization
      ?.getGlobalTranslatorFunction?.()
      ?.then((response) => setGlobalTranslate(() => response));
  }, [commonsNorthboundAPIs.v1.localization]);

  /**
   * It will block rendering until the translate function is available
   * You can remove this if you want to render the children without waiting for the translate function
   * But you should check if translate exists before using it
   */
  if (!translate || !globalTranslate) {
    return null;
  }

  return (
    <section
      className={`${projectNames.sanitizedPackageName}`}
      id={projectNames.packageName}
    >
      <DirectionProvider direction={directionValue}>
        <ThemeProvider
          {...themeProviderProps}
          mode={userThemeMode}
        >
          <dependencyManagerContext.Provider
            value={{
              globalTranslate,
              translate,
              northboundAPIs: commonsNorthboundAPIs,
              properties
            }}
          >
            {children}
          </dependencyManagerContext.Provider>
        </ThemeProvider>
      </DirectionProvider>
    </section>
  );
};

export default DependencyManagerProvider;

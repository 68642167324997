import { JarvisOnboardingComponent } from '@jarvis/react-user-onboarding';
import React, { useCallback, useMemo } from 'react';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import { Stack } from 'src/services/api/hpc3/types';
import getBaseUrl from 'src/utils/getBaseUrl';
import * as T from './types';

const Onboard = ({ onFinished }: T.OnboardPropsType) => {
  const { northboundAPIs } = useDependencyManagerContext();
  const { app, authProvider, localization } = northboundAPIs.v1;
  const stack = app?.getAuthStack();

  // FIXME: it should use authProvider from v2 when it fixes the onTokenExchangeRequired;
  const overWritedAuthProvider = useMemo(
    () => ({
      ...authProvider,

      onTokenExchangeRequired: async () => authProvider.getAccessToken(true)
    }),
    [authProvider]
  );

  const isOAuthToken = useCallback(async () => {
    const jwtPayload = await authProvider.getDecodedUserStratusJWTPayload();
    return jwtPayload?.iss?.includes('oauth-auth');
  }, [authProvider]);

  const baseUrlProvider = useCallback(
    async (apiName, apiVersion) => {
      return getBaseUrl(
        apiVersion,
        apiName,
        await isOAuthToken(),
        Stack[stack]
      );
    },
    [isOAuthToken, stack]
  );

  return (
    <JarvisOnboardingComponent
      baseURLProvider={baseUrlProvider}
      authProvider={overWritedAuthProvider}
      language={localization?.language?.toLowerCase?.() || 'en'}
      country={localization?.country?.toUpperCase?.() || 'US'}
      onFinished={onFinished}
    />
  );
};

export default Onboard;

export function encode(data: any) {
  const value = JSON.stringify(data);

  return window.btoa(value);
}

export function decode(data: string) {
  const result = window.atob(data);

  try {
    return JSON.parse(result);
  } catch {
    return result;
  }
}

export default {
  encode,
  decode
};

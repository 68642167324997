import { isIE } from './../../utils/ie';
import styled from 'styled-components';
import { Card } from '@veneer/core';
import { Button } from '@veneer/core';
import { TextBox } from '@veneer/core';
import tokens from '@veneer/tokens';

type themeModeType = {
  themeMode: string;
};

export const Container = styled.div<themeModeType>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100vw;
  max-width: 100%;
  overflow-x: hidden;

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    max-width: 100%;
  }

  span {
    ${() =>
      isIE() &&
      `color: ${tokens.color.gray7};
       vertical-align: middle;
       width: 100%;
       height: 100%;
       padding: ${tokens.layout.size3};
       transform: none !important;`}
  }

  input {
    ${() => isIE() && `color: ${tokens.color.gray7} !important;`}
  }

  > * {
    max-width: 70%;
  }

  ${({ themeMode }) => {
    if (themeMode === 'contrast')
      return 'background: ' + tokens.color.highContrastGray + ';';
    if (themeMode === 'dark') return 'background: ' + tokens.color.gray7 + ';';
    return 'background: ' + tokens.color.gray2 + ';';
  }}
`;

export const Header = styled.div<themeModeType>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: 20px 0 50px 0;

  svg {
    width: 46px;
    height: 46px;
    margin-bottom: 3px;
  }

  p {
    font-size: 17px;
    color: ${tokens.color.gray7};
    ${() => isIE() && `margin-top: ${tokens.layout.size3};`}
  }

  ${({ themeMode }) => {
    if (themeMode === 'contrast')
      return 'p {color: ' + tokens.color.highContrastGray + '} ;';
    if (themeMode === 'dark') return 'p {color: ' + tokens.color.white + '} ;';
  }}
`;

export const CustomCard = styled(Card)`
  padding: 35px 50px;
  padding-bottom: 45px;
  margin-bottom: 30px;
  width: 320px;
`;

export const CardForm = styled.form`
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 20px;
  }
`;

export const Label = styled.label`
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  color: #212121;
`;

export const CustomTextBox = styled(TextBox)`
  && {
    margin-bottom: 20px;
  }
`;

export const CustomButton = styled(Button)`
  && {
    margin-bottom: 10px;
  }
`;

export const Anchor = styled.a`
  color: #027aae;
  font-size: 13px;
  cursor: pointer;
`;

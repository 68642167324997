import getDecodedStateParam from './getDecodedStateParam';
import store from './store';

export default function getFinalPostLoginRedirect(defaultValue = '/') {
  const urlParams = new URLSearchParams(window.location.search);
  const postLoginRedirectKey = 'postLoginRedirect';
  const inviteKey = 'invite';
  const stateKey = 'state';

  const postLoginRedirectParameter = urlParams.get(postLoginRedirectKey);

  const stateObject = getDecodedStateParam();

  const isUserInvitation = (() => {
    const inviteValue: unknown =
      urlParams.get(inviteKey) || getDecodedStateParam()?.[inviteKey];

    return inviteValue === true || inviteValue === 'true';
  })();

  const manifest = store?.state?.manifest;

  const userOnboardingPath = manifest?.services?.onboarding?.userOnboardingPath;

  if (isUserInvitation && userOnboardingPath) {
    const url = new URL(userOnboardingPath, window.location.origin);
    const urlParams = new URLSearchParams(stateObject);

    const excludeKeys = [postLoginRedirectKey, inviteKey, stateKey];

    urlParams.forEach((v, k) => {
      const invalidKey = excludeKeys.some((keyName) => keyName === k);
      if (v && k && !invalidKey) {
        url.searchParams.append(k, v);
      }
    });

    return url?.pathname + url?.search;
  } else {
    const manifestPostLoginUrl = manifest?.portal?.postLoginRedirect;
    return (
      postLoginRedirectParameter ||
      stateObject?.postLoginRedirect ||
      manifestPostLoginUrl ||
      defaultValue
    );
  }
}

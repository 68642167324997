import React from 'react';
import Login from 'src/components/Login';
import { DependencyManagerProvider } from 'src/contexts/dependencyManager';
import * as T from './types';

// This component wraps your main component with the DependencyManagerProvider
// This provider is responsible to manage global dependencies to your component
const App: React.FC<T.AppPropsType> = ({ properties }) => {
  return (
    <DependencyManagerProvider properties={properties}>
      <Login />
    </DependencyManagerProvider>
  );
};

export default App;

import projectNames from '../contexts/dependencyManager/configs/projectNames';

const createIdHelper = (id: string, replacement = '-'): string => {
  const name = projectNames.packageName
    .replace('@', '')
    .split('/')
    .join(replacement)
    .split('-')
    .join(replacement);
  return `${name}__${id}`;
};

export default createIdHelper;

import { useState, useEffect } from 'react';
import * as T from '../types';

function useAssetImport({
  assetReference,
  ...props
}: T.LazyMfeLoaderPropsType) {
  const [mfe, setMfe] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { singleSpa, mountParcel, ...componentProps } = props;

  useEffect(() => {
    if (assetReference) {
      setIsLoading(true);
      setMfe(undefined);
      System.import(assetReference)
        .then((response) => {
          setMfe(response);
        })
        .catch(() => {
          //
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [assetReference]);

  return {
    mfe,
    isLoading,
    componentProps
  };
}

export default useAssetImport;

import { LogoHp } from '@veneer/core';
import React, { useMemo } from 'react';
import * as S from './styles';

import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import { setCookie } from '../../utils/cookies';
import createIdHelper from '../../utils/createIdHelper';

type ErrorComponentType = {
  title: string;
  errorType?: string;
  onLoginClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

const ErrorComponent = ({
  title,
  onLoginClick,
  // statusCode,
  logoColor,
  ...props
}: ErrorComponentType) => {
  const { translate, northboundAPIs } = useDependencyManagerContext();
  const { theme } = northboundAPIs.v1;
  const themeMode = theme.getUserThemeMode();
  const { errorTitle, messages } = useMemo(() => {
    const cookieName = createIdHelper('setMaxAge0OnLogin', '');
    setCookie(escape(cookieName), 'true');
    // TODO: Resolve this feature
    // switch (statusCode) {
    //   case '400':
    //   case '403':
    //   default:
    //     return {
    //       errorTitle: translate(
    //         'login.error.access-denied.title',
    //         'Access denied'
    //       ),
    //       messages: [
    //         translate(
    //           'login.error.access-denied.full-message',
    //           'You may need to register or be invited first. \n Please contact HP for more information.'
    //         )
    //       ]
    //     };
    // }

    return {
      errorTitle: translate('login.error.access-denied.title', 'Access denied'),
      messages: [
        translate(
          'login.error.access-denied.full-message',
          'You may need to register or be invited first. \n Please contact HP for more information.'
        )
      ]
    };
  }, [translate]);

  return (
    <S.Container
      data-testid={createIdHelper('error_component')}
      themeMode={themeMode}
      {...props}
    >
      <S.Header>
        <LogoHp
          appearance="singlecolor"
          size={48}
          background={logoColor || 'auto'}
        />
        <S.HeaderTitle themeMode={themeMode}>{title}</S.HeaderTitle>
      </S.Header>
      <S.Content data-testid={createIdHelper('access_denied')}>
        <S.FlexColumDiv>
          <S.ErrorTitle themeMode={themeMode}>{errorTitle}</S.ErrorTitle>
          {messages?.map?.((message, index) => (
            <S.Text
              key={index}
              themeMode={themeMode}
            >
              {message}
            </S.Text>
          ))}
        </S.FlexColumDiv>
        <S.LoginLinkButton
          data-testid={createIdHelper('access_denied_login')}
          onClick={onLoginClick}
          themeMode={themeMode}
        >
          Login
        </S.LoginLinkButton>
      </S.Content>
    </S.Container>
  );
};

export default ErrorComponent;

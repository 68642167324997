const encodeProperty = (property: any) =>
  encodeURIComponent(property).replace(/[-.+*]/g, '\\$&');

export const getCookie = (cookieName: string) => {
  const { cookie = '' } = document;
  const cookieNameEncoded = encodeProperty(cookieName);
  const contentLanguage =
    decodeURIComponent(
      cookie.replace(
        new RegExp(
          `(?:(?:^|.*;)\\s*${cookieNameEncoded}\\s*\\=\\s*([^;]*).*$)|^.*$`
        ),
        '$1'
      )
    ) || '';

  const cookieContent =
    Array.isArray(contentLanguage) && contentLanguage.includes(',')
      ? contentLanguage.split(',')[0]
      : contentLanguage;

  return cookieContent;
};

export const deleteCookie = (cookieName: string, path = '/') => {
  document.cookie = `${encodeProperty(
    cookieName
  )}=""; path=${path}; max-age=-1;`;
};

export const setCookie = (
  cookieName: string,
  cookieValue: number | string,
  age = 31536000
): void => {
  if (getCookie(cookieName)) {
    deleteCookie(cookieName);
  }
  document.cookie = `${encodeProperty(cookieName)}=${encodeProperty(
    cookieValue
  )}; max-age=${age}; path=/;`;
};

import { ProgressIndicator } from '@veneer/core';
import React, { Suspense, useEffect, useState } from 'react';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import AuthContextEnum from '../../enums/AuthContextEnum';
import getFinalPostLoginRedirect from '../../utils/getFinalPostLoginRedirect';
import ErrorComponent from '../ErrorComponent';
import LazyMfeLoader from '../LazyMfeLoader';
import LoginForm from '../LoginForm';
import Onboard from '../Onboard';
import { ProviderType } from '../ProviderSelector/types';
import useLoginLocation from './hooks/useLoginLocation';
import { GenerateAuthenticationUrlParams, LoginPropsType } from './types';

const Login: React.FC<LoginPropsType> = () => {
  const { currentPath, error } = useLoginLocation();
  const { northboundAPIs } = useDependencyManagerContext();
  const {
    store,
    navigation,
    localization,
    userInterface,
    tenantHandlerInterface,
    theme,
    orgSelector,
    sessionInterface
  } = northboundAPIs.v1;
  const { postLoginRedirect } = store?.state?.manifest?.services?.login || {};
  const manifest = store?.state?.manifest;
  const preLoginValidation = manifest?.services?.login?.preLoginValidation;
  const [IsPreloginActive, setIsPreloginActive] = useState(
    !!preLoginValidation?.assetReference && !!preLoginValidation?.enable
  );
  const loggedInPath = navigation?.createHref?.({
    pathname: '/loggedin'
  });
  const [email, setEmail] = React.useState<string>('');
  const [providers, setProviders] = React.useState<ProviderType[]>([]);
  const ProviderSelector = React.lazy(() => import('./../ProviderSelector'));

  const logoColor = theme.getUserThemeMode() === 'light' ? 'light' : 'dark';

  const { isUserOnboarded, shouldRenderOnboardingScreen } =
    userInterface.onboarding;

  function handleLoginClick() {
    navigation?.redirect(`${window.location.origin}/login`);
  }

  const [renderComponentObject, setRenderComponentObject] =
    useState<JSX.Element>(<></>);

  const providerLookup = async (email?: string) => {
    const inviteSearchKey = 'invite';
    const isUserInvite =
      new URLSearchParams(window.location.search).get(inviteSearchKey) ===
      'true';

    return await sessionInterface.getProviderList({
      email: email || '',
      provisionUser: isUserInvite
    });
  };

  const handleRedirect = async (providers: ProviderType[]) => {
    // TODO: Move language handling to Commons after Localization refactoring from interface to service.
    const options: GenerateAuthenticationUrlParams = {
      postLoginRedirect: postLoginRedirect,
      authorizationUrl: providers[0]?.authorizationEndpoint
    };

    if (localization?.enabled) {
      options.country = localization.country;
      options.language = localization.language;
    }

    navigation?.redirect(
      await sessionInterface.generateAuthenticationUrl(options)
    );
  };

  const handleSubmit = async (email: string) => {
    const providersList = await providerLookup(email);
    if (providersList.length == 1) {
      void handleRedirect(providersList);
      return;
    }

    setEmail(email);
    setProviders(providersList);
  };

  useEffect(() => {
    if (providers.length < 1) {
      return;
    }

    setRenderComponentObject(
      <Suspense fallback={<ProgressIndicator appearance="circular" />}>
        <ProviderSelector
          userEmail={email}
          providers={providers}
          themeMode={theme.getUserThemeMode()}
        />
      </Suspense>
    );
  }, [providers]);

  useEffect(() => {
    const isInLoggedinPath: boolean = currentPath === loggedInPath;
    if (isInLoggedinPath) {
      if (error) {
        setRenderComponentObject(
          <ErrorComponent
            statusCode={error.status}
            title={manifest?.portal?.appName}
            onLoginClick={handleLoginClick}
            logoColor={logoColor}
          />
        );
      } else {
        renderOnboardingComponent();
      }
    }

    if (!isInLoggedinPath && IsPreloginActive) {
      let { properties } = preLoginValidation || {};
      const { assetReference } = preLoginValidation || {};

      properties = {
        onSuccessValidation: () => setIsPreloginActive(false),
        ...properties
      };

      setRenderComponentObject(
        <LazyMfeLoader {...{ properties, assetReference }} />
      );
    }

    if (!isInLoggedinPath && !IsPreloginActive) {
      setRenderComponentObject(
        <LoginForm
          handleSubmit={handleSubmit}
          logoColor={logoColor}
        />
      );
    }
  }, [IsPreloginActive]);

  async function renderOnboardingComponent() {
    const shouldOnboardUser = !(await isUserOnboarded());
    if (shouldOnboardUser) {
      const renderOnboardingComponent = await shouldRenderOnboardingScreen();
      if (renderOnboardingComponent) {
        setRenderComponentObject(<Onboard onFinished={redirectToHome} />);
        return renderComponentObject;
      }
    }

    redirectToHome();
    return null;
  }

  async function redirectToHome() {
    // TODO: Does it need to be removed?
    // Is it the flow to keep the user token after force login?
    const tenantId = orgSelector?.getTenantCookie();
    if (orgSelector?.isEnabled?.() && tenantId) {
      await tenantHandlerInterface.setTenant({
        authContext: AuthContextEnum.tenant,
        tenantId
      });
    }

    const postLoginRedirect = getFinalPostLoginRedirect(
      manifest?.portal?.postLoginRedirect
    );
    navigation?.redirect(postLoginRedirect);
  }
  return renderComponentObject;
};

export default Login;
